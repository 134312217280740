export default {
  menu: {
    register: "注册",
    company_info: "企业资料",

    overview: "概览",

    product_management: "产品管理",
    product_list: "产品列表",
    product_edit: "修改产品信息",
    product_create: "创建产品信息",
    product_inventory: "产品库存",

    storage_management: "赛易存储仓",
    storage_inventory: "存储仓库存",
    storage_in_warehouse_plan: "入库计划",
    storage_inbound_create: "创建入库计划",
    storage_inbound_confirm: "确认入库计划",
    storage_inbound_shipping: "发运入库计划",
    storage_inbound_detail: "入库计划详情",

    storage_out_plan: "出库计划",
    storage_out_plan_details: "出库计划详情",
    storage_create_out_plan: "创建出库计划",
    storage_add_box_info: "添加箱信息",
    storage_confirm_out_plan: "确认出库计划",
    storage_label_change_list: "存储仓标签服务",
    storage_label_change_detail: "标签任务详情",
    storage_label_change_create: "创建标签任务",

    consignment_send_management: "赛易配送仓",
    consignment_in_plan: "入库计划",
    consignment_inbound_create: "创建入库计划",
    consignment_inbound_confirm: "确认入库计划",
    consignment_inbound_shipping: "发运入库计划",
    consignment_inbound_detail: "入库计划详情",
    consignment_inventory: "配送仓库存",

    consignment_order_list: "配送订单",
    consignment_order_create: "创建配送订单",
    consignment_order_add_product: "添加商品",
    consignment_order_select_logistics: "选择物流",
    consignment_order_details: "订单详情",
    consignment_order_bulk_import:"批量导入配送单",
    consignment_return_temporary_storage:"退货暂存管理",
    consignment_return_temporary_storage_shop:"退货暂存店铺名称管理",
    consignment_allot_plans: "调拨至存储仓的计划",
    consignment_allot_create: "创建调拨计划",
    consignment_allot_set_box: "设置装箱方案",
    consignment_allot_confirm: "确认调拨计划",
    consignment_allot_plan_details: "调拨计划详情",

    consignment_return_channel_list: "渠道退货计划",
    consignment_return_channel_create: "创建退货计划",
    consignment_return_channel_detail: "退货计划详情",
    consignment_inventory_adjustment: "库存调整记录",

    transport_manage: "赛易物流",
    transport_plan: "运输计划",
    transport_create_plan: "创建运输计划",
    transport_set_plan_info: "设置运输信息",
    transport_confirm_plan: "确认运输计划",
    transport_plan_details: "运输计划详情",

    transport_appointment_list: "整柜入库预约",
    transport_appointment_create: "创建预约",
    transport_appointment_details: "整柜入库预约详情",
    transport_import: "批量导入",

    account: "账户",
    account_info: "账户信息",
    account_restpwd: "重置密码",
    account_announcement: "公告",

    about: "关于",
    sdas: "SDAS",

    finance: "财务",
    finance_inventory: "费用清单",
    finance_details: "费用详情",
    finance_inventory_unsettled: "未结算费用清单",
    finance_account_balance: "账户余额",
    recharge_record: "充值记录",
    finance_bill: "月账单",

    customer_service: "客户服务",
    inbound_survey: "入库异常调查",

    print_waybill: "打单服务",
    print_waybill_list: "运单列表",
    print_waybill_create: "创建运单",
    print_waybill_confirm: "选择渠道",
    print_waybill_details: "运单详情",
    print_waybill_batch_import: "批量导入",

    authorization_manage:"授权管理",
    authorization_manage_list:"授权列表",

    auth:"授权",

    user_management:"用户管理",
    user_role_list:"角色管理",
    user_account_list:"账户管理",
    user_account_details:"账户详情",
    user_address:"地址管理",

    transit:"中转仓管理",
    transit_in_plan_list:"入库预约",
    transit_in_plan_create:"创建中转仓入库预约",
    transit_in_plan_add_shipment:"添加入库货件",
    transit_in_plan_edit:"编辑入库预约",
    transit_in_plan_detail:"入库预约详情",

    transit_out_plan_list:"中转仓出库计划",
    transit_out_plan_create:"创建出库计划",
    transit_out_plan_add_shipment:"添加出库货件",
    transit_out_plan_confirm:"确认出库计划",
    transit_out_plan_detail:"中转仓出库计划详情",

    transit_inventory:"库存管理",
    transit_shipment:"货件管理",
    transit_label_change_list: "换标服务",
    transit_label_change_detail: "标签任务详情",
    transit_label_change_create: "创建标签任务",

    setting: "设置",
    setting_notice: "通知设置",
  },
};
